<template>
  <div class="list_content_hotels_group justify-content-around d-flex">
    <label for="sb-locales" class="align-self-center col-2">Hotel</label>
    <b-form-select
    class="col-8"
    id="sb-locales"
    v-model="pickedHotel"
    :options="listHotel"
    text-field="name"
    ></b-form-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'PickerHotel',
  components: {
    BFormSelect,
  },
  props: {
    defaultHotel: {
      type: String,
      value: '',
    },
  },
  data() {
    return {
      pickedHotel: '',
      query: this.$route.query, // temp
    };
  },
  computed: {
    ...mapGetters({
      product: 'GET_PRODUCT',
      // packages: 'GET_PACKAGES',
    }),
    listHotel() {
      const hotelData = [];
      // const tmpProduct = this.packages.find((el) => el.packId === Number(this.query.packId));
      // tmpProduct.hotels.forEach((hotel) => {
      //   if (!hotelData.some((item) => item.value === hotel.La_ID)) {
      //     const localName = tmpProduct?.translations?.hotelName?.[Number(hotel.La_ID)][this.lang] || tmpProduct?.translations?.hotelName?.[Number(hotel.La_ID)].en || hotel.hotelName;
      //     hotelData.push(
      //       {
      //         value: hotel.La_ID,
      //         name: localName,
      //       },
      //     );
      //   }
      // });
      this.product.hotelsAlter.forEach((hotel) => {
        if (!hotelData.some((item) => item.value === hotel.La_ID)) {
          const localName = this.product?.translations?.hotelName?.[Number(hotel.La_ID)]?.[this.lang] || this.product?.translations?.hotelName?.[Number(hotel.La_ID)]?.en || hotel.hotelName;
          hotelData.push(
            {
              value: hotel.La_ID,
              name: localName,
            },
          );
        }
      });
      return hotelData;
    },
  },
  watch: {
    pickedHotel() {
      this.$emit('change', { hotelId: this.pickedHotel });
    },
  },
  mounted() {
    this.pickedHotel = this.defaultHotel || this.listHotel[0].value;
  },
};
</script>

<style>
  .list_content_hotels_group{
    border-radius: 15px;
    padding: 24px 15px;
    margin-top: 0;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
</style>
